define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/discourse-ai/ai-personas/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AiPersonaListEditor
    @personas={{this.allPersonas}}
    @currentPersona={{this.model}}
  />
  */
  {
    "id": "4OQ50K5K",
    "block": "[[[8,[39,0],null,[[\"@personas\",\"@currentPersona\"],[[30,0,[\"allPersonas\"]],[30,0,[\"model\"]]]],null]],[],false,[\"ai-persona-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-plugins/discourse-ai/ai-personas/new.hbs",
    "isStrictMode": false
  });
});