define("discourse/plugins/discourse-ai/discourse/connectors/editor-preview/ai-image-caption-container", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/components/d-textarea", "discourse/modifiers/auto-focus", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/lib/utilities", "@ember/template-factory", "@ember/component"], function (_exports, _component, _modifier, _object, _didInsert, _didUpdate, _willDestroy, _service, _conditionalLoadingSpinner, _dButton, _dTextarea, _autoFocus, _dIcon, _i18n, _utilities, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiImageCaptionContainer = _exports.default = (_class = (_class2 = class AiImageCaptionContainer extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "imageCaptionPopup", _descriptor, this);
      _initializerDefineProperty(this, "appEvents", _descriptor2, this);
      _initializerDefineProperty(this, "composer", _descriptor3, this);
    }
    updateCaption(event1) {
      event1.preventDefault();
      this.imageCaptionPopup.newCaption = event1.target.value;
    }
    saveCaption() {
      const index1 = this.imageCaptionPopup.imageIndex;
      const matchingPlaceholder1 = this.composer.model.reply.match(_utilities.IMAGE_MARKDOWN_REGEX);
      if (matchingPlaceholder1) {
        const match1 = matchingPlaceholder1[index1];
        const replacement1 = match1.replace(_utilities.IMAGE_MARKDOWN_REGEX, `![${this.imageCaptionPopup.newCaption}|$2$3$4]($5)`);
        if (match1) {
          this.appEvents.trigger("composer:replace-text", match1, replacement1);
        }
      }
      this.hidePopup();
    }
    resizeTextarea(target1) {
      const style1 = window.getComputedStyle(target1);
      // scrollbars will show based on scrollHeight alone
      // so we need to consider borders too
      const borderTopWidth1 = parseInt(style1.borderTopWidth, 10);
      const borderBottomWidth1 = parseInt(style1.borderBottomWidth, 10);
      target1.scrollTop = 0;
      target1.style.height = `${target1.scrollHeight + borderTopWidth1 + borderBottomWidth1}px`;
    }
    hidePopup() {
      this.imageCaptionPopup.showPopup = false;
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.imageCaptionPopup.showPopup}}
        <div
          class="composer-popup education-message ai-caption-popup"
          {{willDestroy this.hidePopup}}
        >
          <ConditionalLoadingSpinner
            @condition={{this.imageCaptionPopup.loading}}
          >
            <DTextarea
              {{didInsert this.resizeTextarea}}
              {{didUpdate this.resizeTextarea this.imageCaptionPopup.newCaption}}
              @value={{this.imageCaptionPopup.newCaption}}
              {{on "change" this.updateCaption}}
              {{autoFocus}}
            />
          </ConditionalLoadingSpinner>
  
          <div class="actions">
            <DButton
              class="btn-primary"
              @label="discourse_ai.ai_helper.image_caption.save_caption"
              @icon="check"
              @action={{this.saveCaption}}
            />
            <DButton
              class="btn-flat"
              @label="cancel"
              @action={{this.hidePopup}}
            />
  
            <span class="credits">
              {{icon "discourse-sparkles"}}
              <span>{{i18n "discourse_ai.ai_helper.image_caption.credits"}}</span>
            </span>
          </div>
        </div>
      {{/if}}
    
  */
  {
    "id": "8IZQ15+0",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"imageCaptionPopup\",\"showPopup\"]],[[[1,\"      \"],[11,0],[24,0,\"composer-popup education-message ai-caption-popup\"],[4,[32,0],[[30,0,[\"hidePopup\"]]],null],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@condition\"],[[30,0,[\"imageCaptionPopup\",\"loading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],[[4,[32,3],[[30,0,[\"resizeTextarea\"]]],null],[4,[32,4],[[30,0,[\"resizeTextarea\"]],[30,0,[\"imageCaptionPopup\",\"newCaption\"]]],null],[4,[32,5],[\"change\",[30,0,[\"updateCaption\"]]],null],[4,[32,6],null,null]],[[\"@value\"],[[30,0,[\"imageCaptionPopup\",\"newCaption\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n\\n        \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n          \"],[8,[32,7],[[24,0,\"btn-primary\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_ai.ai_helper.image_caption.save_caption\",\"check\",[30,0,[\"saveCaption\"]]]],null],[1,\"\\n          \"],[8,[32,7],[[24,0,\"btn-flat\"]],[[\"@label\",\"@action\"],[\"cancel\",[30,0,[\"hidePopup\"]]]],null],[1,\"\\n\\n          \"],[10,1],[14,0,\"credits\"],[12],[1,\"\\n            \"],[1,[28,[32,8],[\"discourse-sparkles\"],null]],[1,\"\\n            \"],[10,1],[12],[1,[28,[32,9],[\"discourse_ai.ai_helper.image_caption.credits\"],null]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/editor-preview/ai-image-caption-container.js",
    "scope": () => [_willDestroy.default, _conditionalLoadingSpinner.default, _dTextarea.default, _didInsert.default, _didUpdate.default, _modifier.on, _autoFocus.default, _dButton.default, _dIcon.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "imageCaptionPopup", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCaption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateCaption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCaption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCaption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resizeTextarea", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resizeTextarea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hidePopup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hidePopup"), _class.prototype)), _class);
});