define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-discourse-ai-ai-personas", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model() {
      return this.store.findAll("ai-persona");
    }
  });
});