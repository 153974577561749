define("discourse/plugins/discourse-ai/discourse/components/ai-persona-command-option-editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiPersonaCommandOptionEditor = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="control-group ai-persona-command-option-editor">
      <label>
        {{@option.name}}
      </label>
      <div class="">
        <Input @value={{@option.value.value}} />
      </div>
      <div class="ai-persona-command-option-editor__instructions">
        {{@option.description}}
      </div>
    </div>
  
  */
  {
    "id": "WHtmf9wt",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"control-group ai-persona-command-option-editor\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@value\"],[[30,1,[\"value\",\"value\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ai-persona-command-option-editor__instructions\"],[12],[1,\"\\n      \"],[1,[30,1,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@option\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-persona-command-option-editor.js",
    "scope": () => [_component.Input],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = AiPersonaCommandOptionEditor;
});