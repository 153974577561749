define("discourse/plugins/discourse-ai/discourse/connectors/after-composer-title-input/ai-title-suggestion", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-suggestion-dropdown", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/template-factory", "@ember/component"], function (_exports, _component, _aiSuggestionDropdown, _showAiHelper, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class AiTitleSuggestion extends _component.default {
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showComposerAIHelper)(outletArgs1, helper1, "suggestions");
    }
  }
  _exports.default = AiTitleSuggestion;
  _class = AiTitleSuggestion;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <AISuggestionDropdown
        @mode="suggest_title"
        @composer={{@outletArgs.composer}}
        class="suggest-titles-button"
      />
    
  */
  {
    "id": "4En7i5/x",
    "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"suggest-titles-button\"]],[[\"@mode\",\"@composer\"],[\"suggest_title\",[30,1,[\"composer\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-composer-title-input/ai-title-suggestion.js",
    "scope": () => [_aiSuggestionDropdown.default],
    "isStrictMode": true
  }), _class);
});