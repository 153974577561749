define("discourse/plugins/discourse-ai/discourse/connectors/after-composer-tag-input/ai-tag-suggestion", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-ai/discourse/components/ai-suggestion-dropdown", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _aiSuggestionDropdown, _showAiHelper, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let AiTagSuggestion = _exports.default = (_class = (_class2 = class AiTagSuggestion extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showComposerAIHelper)(outletArgs1, helper1, "suggestions");
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.siteSettings.ai_embeddings_enabled}}
        <AISuggestionDropdown
          @mode="suggest_tags"
          @composer={{@outletArgs.composer}}
          class="suggest-tags-button"
        />
      {{/if}}
    
  */
  {
    "id": "qB6wq01S",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"ai_embeddings_enabled\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"suggest-tags-button\"]],[[\"@mode\",\"@composer\"],[\"suggest_tags\",[30,1,[\"composer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-composer-tag-input/ai-tag-suggestion.js",
    "scope": () => [_aiSuggestionDropdown.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});