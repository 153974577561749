define("discourse/plugins/discourse-ai/initializers/ai-bot-replies", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/components/modal/share-modal", "discourse/plugins/discourse-ai/discourse/lib/ai-streamer", "discourse/plugins/discourse-ai/discourse/lib/copy-conversation", "@ember/template-factory"], function (_exports, _ajax, _ajaxError, _pluginApi, _renderGlimmer, _aiBotHelper, _shareModal, _aiStreamer, _copyConversation, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AUTO_COPY_THRESHOLD = 4;
  function isGPTBot(user) {
    return user && [-110, -111, -112, -113, -114, -115, -116].includes(user.id);
  }
  function attachHeaderIcon(api) {
    const settings = api.container.lookup("service:site-settings");
    const enabledBots = settings.ai_bot_add_to_header ? settings.ai_bot_enabled_chat_bots.split("|").filter(Boolean) : [];
    if (enabledBots.length > 0) {
      api.attachWidgetAction("header", "showAiBotPanel", function () {
        this.state.botSelectorVisible = true;
      });
      api.attachWidgetAction("header", "hideAiBotPanel", function () {
        this.state.botSelectorVisible = false;
      });
      api.decorateWidget("header-icons:before", helper => {
        return helper.attach("header-dropdown", {
          title: "discourse_ai.ai_bot.shortcut_title",
          icon: "robot",
          action: "clickStartAiBotChat",
          active: false,
          classNames: ["ai-bot-button"]
        });
      });
      if (enabledBots.length === 1) {
        api.attachWidgetAction("header", "clickStartAiBotChat", function () {
          (0, _aiBotHelper.composeAiBotMessage)(enabledBots[0], api.container.lookup("service:composer"));
        });
      } else {
        api.attachWidgetAction("header", "clickStartAiBotChat", function () {
          this.sendWidgetAction("showAiBotPanel");
        });
      }
      api.addHeaderPanel("ai-bot-header-panel-wrapper", "botSelectorVisible", function () {
        return {};
      });
    }
  }
  function initializeAIBotReplies(api) {
    api.addPostMenuButton("cancel-gpt", post => {
      if (isGPTBot(post.user)) {
        return {
          icon: "pause",
          action: "cancelStreaming",
          title: "discourse_ai.ai_bot.cancel_streaming",
          className: "btn btn-default cancel-streaming",
          position: "first"
        };
      }
    });
    api.attachWidgetAction("post", "cancelStreaming", function () {
      (0, _ajax.ajax)(`/discourse-ai/ai-bot/post/${this.model.id}/stop-streaming`, {
        type: "POST"
      }).then(() => {
        document.querySelector(`#post_${this.model.post_number}`).classList.remove("streaming");
      }).catch(_ajaxError.popupAjaxError);
    });
    api.modifyClass("controller:topic", {
      pluginId: "discourse-ai",
      onAIBotStreamedReply: function (data) {
        (0, _aiStreamer.default)(this.model.postStream, data);
      },
      subscribe: function () {
        this._super();
        if (this.model.isPrivateMessage && this.model.details.allowed_users && this.model.details.allowed_users.filter(isGPTBot).length >= 1) {
          // we attempt to recover the last message in the bus
          // so we subscribe at -2
          this.messageBus.subscribe(`discourse-ai/ai-bot/topic/${this.model.id}`, this.onAIBotStreamedReply.bind(this), -2);
        }
      },
      unsubscribe: function () {
        this.messageBus.unsubscribe("discourse-ai/ai-bot/topic/*");
        this._super();
      }
    });
  }
  function initializePersonaDecorator(api) {
    let topicController = null;
    api.decorateWidget(`poster-name:after`, dec => {
      if (!isGPTBot(dec.attrs.user)) {
        return;
      }
      // this is hacky and will need to change
      // trouble is we need to get the model for the topic
      // and it is not available in the decorator
      // long term this will not be a problem once we remove widgets and
      // have a saner structure for our model
      topicController = topicController || api.container.lookup("controller:topic");
      return dec.widget.attach("persona-flair", {
        topicController
      });
    });
    (0, _renderGlimmer.registerWidgetShim)("persona-flair", "span.persona-flair", (0, _templateFactory.createTemplateFactory)(
    /*
      {{@data.topicController.model.ai_persona_name}}
    */
    {
      "id": "9jYzNJdM",
      "block": "[[[1,[30,1,[\"topicController\",\"model\",\"ai_persona_name\"]]]],[\"@data\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/initializers/ai-bot-replies.js",
      "isStrictMode": false
    }));
  }
  const MAX_PERSONA_USER_ID = -1200;
  function initializeShareButton(api) {
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.ai_enabled_chat_bots) {
      return;
    }
    let shareAiResponse = async function (_ref) {
      let {
        post,
        showFeedback
      } = _ref;
      if (post.post_number <= AUTO_COPY_THRESHOLD) {
        await (0, _copyConversation.default)(post.topic, 1, post.post_number);
        showFeedback("discourse_ai.ai_bot.conversation_shared");
      } else {
        modal.show(_shareModal.default, {
          model: post
        });
      }
    };
    api.addPostMenuButton("share", post => {
      // for backwards compat so we don't break if topic is undefined
      if (post.topic?.archetype !== "private_message") {
        return;
      }
      if (!currentUser.ai_enabled_chat_bots.any(bot => post.username === bot.username)) {
        // special handling for personas (persona bot users start at ID -1200 and go down)
        if (post.user_id > MAX_PERSONA_USER_ID) {
          return;
        }
      }
      return {
        action: shareAiResponse,
        icon: "share",
        className: "post-action-menu__share",
        title: "discourse_ai.ai_bot.share",
        position: "first"
      };
    });
    const modal = api.container.lookup("service:modal");
  }
  var _default = _exports.default = {
    name: "discourse-ai-bot-replies",
    initialize(container) {
      const settings = container.lookup("service:site-settings");
      const user = container.lookup("service:current-user");
      if (user?.ai_enabled_chat_bots) {
        if (settings.ai_bot_add_to_header) {
          (0, _pluginApi.withPluginApi)("1.6.0", attachHeaderIcon);
        }
        (0, _pluginApi.withPluginApi)("1.6.0", initializeAIBotReplies);
        (0, _pluginApi.withPluginApi)("1.6.0", initializePersonaDecorator);
        (0, _pluginApi.withPluginApi)("1.22.0", api => initializeShareButton(api, container));
      }
    }
  };
});