define("discourse/plugins/discourse-ai/discourse/components/ai-command-selector", ["exports", "@ember/object", "select-kit/components/multi-select"], function (_exports, _object, _multiSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    _modelDisabledChanged: (0, _object.observer)("attrs.disabled", function () {
      this.selectKit.options.set("disabled", this.get("attrs.disabled.value"));
    }),
    content: (0, _object.computed)(function () {
      return this.commands;
    }),
    value: "",
    selectKitOptions: {
      filterable: true
    }
  });
});